import { useStore } from "../../@core/galerie/store"
import React, { useEffect, useRef, useState } from "react"
import { animated, useSpring } from "react-spring"
import LANGUAGES from "../../Const/languages"
import { useStore as mainUseStore } from "../../@core/scenes/mainStore"

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 5}px,${y / 5}px,0)`


export const DragAndDropUI = () => {
  const LANG = mainUseStore(state => state.language)

  const [onRelease, setOnRelease] = useState(false)
  const uiRef = useRef()

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 } }))


  const animOpacity = useSpring({
    opacity: onRelease ? 0 : 1,
    delay: 750,
    config: {
      duration:250,
    },
    onRest: () => {
      if(onRelease && uiRef.current)  uiRef.current.style.display = "none"
    }
  })

  const onMouseLeaveHandler = () => {
    setOnRelease(true)
  }


  return (
  <animated.div style={animOpacity} ref={uiRef}>
    <animated.div
      onMouseLeave= {onMouseLeaveHandler}
      onTouchStart= {onMouseLeaveHandler}
      className={`ui-circle-text drag-n-drop-ui`}
      style={{
        zIndex: 1000
      } } >
      <ArrowLeft />
      {LANGUAGES[LANG].clickAndDrag}
      <ArrowRight />
    </animated.div>
  </animated.div>
  )
}



const ArrowRight = () => {
  return (
    <div className="arrow-right">
      <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.31348 1L10.5059 10.1924C11.6774 11.364 11.6774 13.2635 10.5059 14.435L1.31348 23.6274"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"/>
      </svg>
    </div>
  )
}

const ArrowLeft = () => {
  return (
    <div className="arrow-left">
      <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3135 1L2.12109 10.1924C0.949515 11.364 0.949515 13.2635 2.12109 14.435L11.3135 23.6274"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"/>
      </svg>

    </div>
  )
}
