import React, { useEffect } from "react"
import { useStore } from "../@core/scenes/mainStore"
import Layout from "../components/layout"
import { PortraitsSlider } from "../components/sections/portraitsSlider"
import { BtnRetour } from "../components/ui/btnRetour"
import { DragAndDropUI } from "../components/ui/dragAndDrop"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { isMobile } from "../@utils/isMobile"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import LANGUAGES from "../Const/languages"
gsap.registerPlugin(ScrollTrigger)



export default function LesVisionnaires({data}) {
  const LANG = useStore(state => state.language)

  const visionnairesPosts = data.allWpVisionnaire.nodes.filter(
    value => value.language.code === LANG
  )

  let Alltrigger = ScrollTrigger.getAll()

  for (let i = 0; i < Alltrigger.length; i++) {
    Alltrigger[i].kill(true)
  }

  // Logo color
  useEffect(()=> {
    useStore.setState({theme: "white"})
    if (useStore.getState().pointsCloud !==null) {
      useStore.getState().pointsCloud.morphTo("positionC")
    }

  }, [])

  return (
    <Layout localClass="visionnaires-page" showPointClouds={true}>
      <Helmet>
          <title>{LANGUAGES[LANG].visionariesTitle}</title>
      </Helmet>
      <div className="page-visionnaires">
        <h1 className="page-title">{LANGUAGES[LANG].visionariesTitle}</h1>
        <BtnRetour link='/' targetState={{fromVisionnaire: true}}/>
        <PortraitsSlider data={visionnairesPosts} />

        {!isMobile() && <DragAndDropUI />}
      </div>
    </Layout>
  )
}

// GraphQL query Projets
//highlight-start
export const query = graphql`
  query {
    allWpVisionnaire {
      nodes {
        language {
          code
        }
        title
        uri
        slug
        visionnaire_post {
          entrepriseLinkTest {
            ... on WpEntreprise {
              id
              title
            }
          }
          portrait {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          brandIcon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
//highlight-end
