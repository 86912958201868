import { Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import logo_test from "../../../static/visionnaires/logo_test.png"
import gsap from "gsap"
import { Draggable } from "gsap/all"
import { InertiaPlugin } from "../../vendors/gsap/InertiaPlugin"
import { getImageSrcFromWpData } from "../../@utils/wpData.utils"
import { isMobile } from "../../@utils/isMobile"
import { useStore } from "../../@core/scenes/mainStore"

export const PortraitsSlider = ({ data }) => {
  // animation Drag
  const LANG = useStore(state => state.language)


  useEffect(() => {
    if (isMobile() || isMobile() === undefined) return

    gsap.registerPlugin(Draggable, InertiaPlugin)
    const wrapper = document.querySelector(".draggable-wrapper")
    const boxes = document.querySelector(".boxes")
    const proxy = document.createElement("div")
    const numBoxes = data.length
    const boxWidth = Math.floor(window.innerWidth / 4)
    const boxHeight = 800
    const viewWidth = window.innerWidth
    const wrapWidth = numBoxes * boxWidth
    const wrapVal = gsap.utils.wrap(0, wrapWidth)

    gsap.set([wrapper], { height: boxHeight })
    gsap.set(boxes, { left: -boxWidth })

    for (let i = 1; i <= numBoxes; i++) {
      const box = boxes.children[i - 1]
      gsap.set(box, { x: i * boxWidth, width: boxWidth, height: boxHeight })
    }

    const animation = gsap.to(".box", {
      duration: 1,
      x: `+=${wrapWidth}`,
      ease: "none",
      paused: true,
      modifiers: {
        x: function (x, target) {
          x = parseInt(x) % wrapWidth
          target.style.visibility =
            x - boxWidth > viewWidth ? "hidden" : "visible"
          return `${x}px`
        },
      },
    })

    let draggable = new Draggable(proxy, {
      type: "x",
      trigger: ".draggable-wrapper",
      inertia: true,
      onDrag: updateProgress,
      onThrowUpdate: updateProgress,
      snap: {
        x: x => {
          return Math.round(x / boxWidth) * boxWidth
        },
      },
    })

    window.addEventListener("resize", resize)

    function resize() {
      // viewWidth = viewport.offsetWidth
      animation.render(animation.time(), false, true)
    }

    function updateProgress() {
      animation.progress(wrapVal(this.x) / wrapWidth)
    }
  }, [LANG])

  return (
    <div className="portraits-slider-container section-dark draggable-wrapper">
      <div className="boxes">
        {data.map((value, index) => {
          let brandTitle = ""

          if (value.visionnaire_post.entrepriseLinkTest !== null && value.visionnaire_post.entrepriseLinkTest !== undefined )
            brandTitle = value.visionnaire_post.entrepriseLinkTest.title

          return (
            <PortraitThumb
              name={value.title}
              url={value.slug}
              dataImage={value.visionnaire_post.portrait}
              logo={value.visionnaire_post.brandIcon}
              key={index}
              brandTitle={brandTitle}
            />
          )
        })}
      </div>
    </div>
  )
}

const PortraitThumb = props => {
  const thumbnail = getImageSrcFromWpData(props.dataImage)
  const logoBrand = getImageSrcFromWpData(props.logo)

  return (
    <div className="portrait-thumb-slider box">
      <div className="thumbnail">
        <Link to={`/visionnaire/${props.url}`}>
          <img src={thumbnail} className="portrait-grey" />
          {logoBrand && <img src={logoBrand} className="portrait-logo-hover" />}
        </Link>
      </div>
      <h2>{props.name}</h2>
      <Link to={`/visionnaire/${props.url}`} className="discover-link">
        <span>{props.brandTitle}</span>
      </Link>
    </div>
  )
}
