import * as THREE from "three"
import vertexShader from "../shaders/pointsParticules/explode_vs.glsl"
import fragmentShader from "../shaders/pointsParticules/explode_fs.glsl"
import {
  generatePointsFromTexture,
  generatePositionsFromFormula,
  getColorDatasFromTexture
} from "../../@utils/CloudPoints"
import ParametricEquations from "../../@utils/ParametricEquations"
import { sleep } from "../../@utils/utils"

/**
 * Cloud Point Plane
 * Input : texture
 *
 *
 */

class ExplodeVisuelSingleton {
  constructor() {
    this.pointsRoot = null
    this.bufferAttribute = null
    this.timer = 0
    this.exploding = false
    // Image Ratio Size
    this.ratio = 0.35

    if (typeof window !== `undefined`) {
      this.sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    } else {
      this.sizes = {
        width: 800,
        height: 600,
      }
    }

    this.material = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      depthTest: true,
      alphaTest: 0.5,
      transparent: true,
      vertexColors: true,
      uniforms: {
        uTime: { type: "f", value: 0 },
        uTextureSize: {
          value: new THREE.Vector2(256, 256),
        },
        uExplode: { type: "f", value: 0 },
        uResolution: {
          value: new THREE.Vector2(this.sizes.width, this.sizes.height),
        },
      },
    })
  }

  /**
   * On créer un nouveau nuage de point avec la texture
   * TODO: voir si on ne créer pas un nuage de point vide à l'instanciation,
   * et on modifie uniquement la couleur
   * @param {*} texture
   * @returns Mesh
   */
  createMesh(texture) {
    this.material.uniforms.uTextureSize.value = new THREE.Vector2(
      texture.image.width,
      texture.image.height
    )
    this.material.uniformsNeedUpdate = true

    // New THREE.Points
    this.pointsRoot = generatePointsFromTexture(
      texture.image,
      this.material,
      this.ratio
    )
    // Generate Positions Attribute from Target Models and give position count to ensure Equal length array
    const rootPositionsCount = this.pointsRoot.geometry.attributes.position
      .count

    const positionB = generatePositionsFromFormula(
      ParametricEquations.mobius3d,
      rootPositionsCount,
      216,
      156
    )

    // Buffer Attribute,
    // TODO: Reset quand on change d'image pour pas en recréé un autre
    if (this.bufferAttribute === null)
      this.bufferAttribute = new THREE.BufferAttribute(positionB, 3)

    // TODO: verif si attribute "positionB" est déjà set alors on modifie
    this.pointsRoot.geometry.setAttribute("positionB", this.bufferAttribute)
    this.pointsRoot.scale.set(0.25, 0.25, 0.25)


    return this.pointsRoot
  }
  // change attributes colors of the geometry
  updateColor(texture) {
    const newColors = getColorDatasFromTexture(texture, this.ratio)

    const colors = this.pointsRoot.geometry.attributes.color.array
    for (let i = 0, j = 0; i < colors.length; i++, j += 4) {
      colors[j] = newColors[j];
      colors[j + 1] = newColors[j + 1];
      colors[j + 2 ] = newColors[j + 2];
      colors[j + 3 ] = newColors[j + 3];
    }
    this.pointsRoot.geometry.attributes.color.needsUpdate = true;
  }
  reset() {
    this.pointsRoot.position.setY(-100)
    this.timer = 0
    this.exploding = false
    this.pointsRoot.material.uniforms.uExplode.value = 0

    // Clean pointsRoot - (geometry with position and color + Material to clean) reset memory
    this.pointsRoot.geometry.dispose()
    this.pointsRoot.material.dispose()
  }
  async explode(targetItem, duration) {
    // update colors from texture
    this.updateColor(targetItem.texture.image)

    const targetPosition = targetItem.mesh.position
    this.pointsRoot.position.set(targetPosition.x, targetPosition.y, targetPosition.z )
    this.pointsRoot.scale.set(0.3, 0.3, 0.3)
    this.pointsRoot.material.uniforms.uExplode.value = 1

    this.timer = 0

    this.exploding = true
    // Timer
    await sleep(duration * 1000)
    this.reset()

  }
  update(elapsedTime) {
    // update material
    if (this.exploding ) {
      if (this.timer < 1) this.timer += 0.02
    }
    this.pointsRoot.material.uniforms.uTime.value = this.timer
  }
}

const ExplodeVisuel = new ExplodeVisuelSingleton()
export default ExplodeVisuel
