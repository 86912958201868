import { Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import LANGUAGES from "../../Const/languages"
import { useStore } from "../../@core/scenes/mainStore"


export const BtnRetour = ({link, targetState=null}) => {
  const LANG = useStore(state => state.language)

  return (
    <Link to={link} className="btn-back" state={targetState}>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M4.72266 9L1.89423 6.17157C1.11318 5.39052 1.11318 4.12419 1.89423 3.34315L4.72266 0.514719"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span>{LANGUAGES[LANG].back}</span>
    </Link>
  )
}
