import create from "zustand"
import gsap from "gsap"
import ExplodeVisuel from "./ExplodeVisuel"
import { navigate } from "gatsby"
import { useStore as useMainStore} from "../scenes/mainStore"


export const useStore = create((set, get) => ({
  assetsLoaded: false,
  isIntroOver: false,
  currentSlideNum: 6,
  nbSlides: null,
  camera: null,
  cameraTarget: null,
  mouseDiff: { x: 0, y: 0 },
  carrousel: null,
  datasSlides: null,
  itemExploding: false,
  onHoverThumb: false,
  sortPanelOpen: false,
  mouse: { x: 0, y: 0 },
  dragging: false,
  actions: {
    moveRight: carrousel => {
      if (carrousel === undefined) carrousel = get().carrousel
      if (carrousel.isPlaying || get().itemExploding) return

      if (get().currentSlideNum < get().nbSlides - 1)
        set(state => ({ currentSlideNum: state.currentSlideNum + 1 }))
      else set(state => ({ currentSlideNum: 0 }))
      carrousel.move("right")
    },
    moveLeft: carrousel => {
      if (carrousel === undefined) carrousel = get().carrousel
      if (carrousel.isPlaying || get().itemExploding) return

      if (get().currentSlideNum > 0)
        set(state => ({ currentSlideNum: state.currentSlideNum - 1 }))
      else set(state => ({ currentSlideNum: state.nbSlides - 1 }))
      carrousel.move("left")
    },
    zoomAndExplose: carrouselItem => {
      if (get().dragging) return

      // 1. on positionne ExplodeVisuel - carrousel.explodeMesh
      // 2. on lance l'animation de l'explosion
      // if carroussel moving don't allow exploding

      set(state => ({ itemExploding: true }))
      const duration = 2.5
      ExplodeVisuel.explode(carrouselItem, duration)
      // camera position
      const camera = get().camera
      carrouselItem.hide()

      // UI Transition Down
      const TransitionPage = document.getElementById("TransitionPage")
      const panel = TransitionPage.getElementsByClassName("panel")[0]

      // TEMP START
      // set(state => ({ itemExploding: false }))
      // return
      // TEMP END

      gsap.to(panel, {
        height: "100%",
        duration: 1,
        delay: duration - 1,
        ease: "power4.inOut",
      })
      // ZOOM
      gsap.to(camera.position, {
        x: 5,
        y: 10,
        z: -45,
        duration: duration,
        ease: "power4.inOut",
        onComplete: () => {
          // Go to BRand URL
          // 1. get ID from
          // const ID = carrouselItem.order

          // LANGUAGE
          const LANG = useMainStore.getState().language
          let url = carrouselItem.slug.fr

          if (LANG !== "FR")
            url = carrouselItem.slug.en

          // End Explosion + go to Brand Page
          set(state => ({ itemExploding: false }))
          get().actions.galerieDisolveAnimation(url)
        },
      })
    },
    galerieDisolveAnimation: url => {
      set(state => ({ isIntroOver: false }))
      // Gatsby Link but programmaticaly

      navigate("/" + url)
    },
    sortCategoryZoomOut: () => {
      const camera = get().camera
      gsap.to(camera.position, {
        z: 260,
        duration: 1,
        ease: "power4.inOut",
      })
    },
    sortCategoryZoomIn: () => {
      const camera = get().camera
      gsap.to(camera.position, {
        z: 10,
        duration: 1,
        ease: "power4.inOut",
      })
    },
    startCameraIntro: () => {
      const duration = 5
      const camera = get().camera
      const cameraTarget = get().cameraTarget
      // const customEase =  CustomEase.create("custom", "M0,0 C0.402,0.144 0.428,0.148 0.56,0.472 0.657,0.711 0.63,0.968 1,1 ")
      gsap.to(camera.position, {
        x: 20,
        y: 10,
        z: 10,
        duration: duration,
        ease: "power4.inOut",
        onComplete: () => set(state => ({ isIntroOver: true })),
      })

      gsap.from(cameraTarget, {
        x: 120,
        y: 60,
        z: 150,
        duration: duration,
        ease: "power4.inOut",
      })
    },
  },
}))
